<template>
    <div>
        <div class="frm" ref="load">
            <el-table :data="selectList" :row-key="getRowKeys" ref="multipleTable"
                @selection-change="handleSelectChange" :reserve-selection="true" :row-class-name="columnClass">
                <el-table-column prop="id"
                    type="selection" :reserve-selection="true" :selectable="selectionSwitch"
                    width="60">
                </el-table-column>
                <el-table-column prop="user_name" label="印章持有人">
                </el-table-column>
                <el-table-column prop="member_id" label="工号">
                </el-table-column>
                <el-table-column prop="mobile" label="手机号">
                </el-table-column>
                <el-table-column prop="name" label="部门">
                    <template slot-scope="scope">
                        <div v-if="scope.row.depts && scope.row.depts.length>0">{{scope.row.depts[0].name}}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="职位">
                    <template slot-scope="scope">
                        <div v-if="scope.row.roles && scope.row.roles.length>0">{{scope.row.roles[0].name}}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="cert_stat" label="实名状态">
                    <template slot-scope="scope">
                        <div v-if="scope.row.cert_stat==0">未认证</div>
                        <div v-if="scope.row.cert_stat==1">认证中</div>
                        <div v-if="scope.row.cert_stat==2" style="color:#2BBAAE">认证通过</div>
                        <div v-if="scope.row.cert_stat==3" style="color:#F66F6A">认证未通过</div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="page flex">
            <div class="flex-1">
                <el-pagination background layout="prev, pager, next" :current-page.sync="queryOpt.page" :page-size="queryOpt.pagesize" :total="total"></el-pagination>
            </div>
            <div v-if="allSelectedId" style="line-height:32px;padding:0 20px;font-size:15px;">已选中
                <span style="color:#409EFF;">{{allSelectedId.length}}</span>
            </div>
        </div>
        <div class="flex flex-justify-around" style="padding: 30px 140px 0;">
            <el-button @click="hide()" style="width:76px;" size="small" type="primary" plain>取消</el-button>
            <el-button @click="addGoods()" style="width:76px;" size="small" type="primary">确定</el-button>
        </div>
    </div>
</template>

<script>
    import { debounce } from 'lodash';
    import { listCompMember,addSealMember } from "@/service/contract"
    export default {
        props:['form'],
        data() {
            return {
                selfIndex:null,
                queryOpt: {
                    seal_type:1, // 印章类型，0保留，1设计作品版权出售合同章，2商品打样合同章，3代加工合同章
                    page:1,
                    pagesize:999,
                },
                total: 10,
                selectList: [],
                allSelectedId: [],
                ruleForm: null,
            }
        },
        watch: {
            'from.seal_type':{
                handler() {
                    this.queryOpt.seal_type = this.form.seal_type;
                },
                deep: true
            },
            'queryOpt.page': {
                handler() {
                    this.debounceRefreshItems();
                },
                deep: true
            },
        },
        created() {
            this.queryOpt.seal_type = this.form.seal_type;
            this.debounceRefreshItems = debounce(this.refreshItems, 500);
            this.refreshItems();
        },
        mounted() {},
        methods: {
            goto(path) {
                this.$router.push(path);
            },
            refreshItems() {
                const _loading = this.$loading({
                    target:this.$refs.load,
                    lock: true,
                    text: "拼命加载中....",
                });
                listCompMember(this.queryOpt).then(rst=>{
                    // this.total=rst.total;
                    this.selectList = rst;
                    console.log(this.selectList);
                    _loading.close();
                }).catch(err => {
                    _loading.close();
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            getRowKeys(row) { 
                return row.id;
            },
            selectionSwitch(row, index) { // 判断CheckBox的可选性
                if (row.cert_stat == 2) {
                    return true;
                }
            },
            columnClass({ row, column, rowIndex, columnIndex }) { //给判断项添加模糊效果
                if (row.cert_stat != 2) {
                    return "columnClass";
                }
            },
            //确定选中
            handleSelectChange(rows) {
                console.log(rows);
                //将选中赋值到回显和传参数组
                this.templateRadio = rows;
                this.allSelectedId = [];
                if (rows) {
                    rows.forEach(row => {
                        this.allSelectedId.push(row.user_id);
                    });
                }
                console.log(this.allSelectedId);
            },
            hide() {
                this.$emit('hide');
            },
            // 添加到分销商品库
            addGoods() {
                this.ruleForm = {
                    seal_type: this.queryOpt.seal_type,
                    user_ids: this.allSelectedId
                }
                console.log(this.ruleForm);
                addSealMember(this.ruleForm).then(rst => {
                    console.log('addSealMember',rst);
                    this.hide();
                    this.$message.success("新增印章持有人成功");
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
        }
    }
</script>
<style scoped>
    .frm {
        height:480px;
        overflow:auto;
    }
    div.frm>>> .el-table {
        border-top: 1px solid #EBEEF5;
        border-left: 1px solid #EBEEF5;
        border-right: 1px solid #EBEEF5;
    }
    div.frm>>> .columnClass {
        color: #CCCCCC;
    }
    div.frm>>> .cell {
        text-align: center;
    }
    .page {
        padding:10px;
        border:1px solid #EBEEF5;
    }
</style>