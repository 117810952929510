<template>
    <div class="zt-page-content">
        <div class="tab_menu flex">
            <div v-for="(item,index) in tabList" :key="index" @click="tab(index)" class="flex menu_item" :class="{'cur':tabIndex == index}">
                <div class="menu_item_button">{{item.name}}</div>
            </div>
        </div>
        <div class="box flex">
            <div class="box-left flex flex-center">
                <div>
                    <div class="bl-title">印章名称</div>
                    <div class="bl-name">{{tabList[tabIndex].name}}</div>
                    <div class="bl-img">
                        <img src="@/assets/images/comp/stamp.png">
                    </div>
                    <div>印章图片</div>
                </div>
            </div>
            <div class="box-right flex-1" style="overflow: hidden;">
                <div style="text-align:right;padding-bottom:8px">
                    <el-button @click="toAdd()" type="primary" size="small">新增持有人</el-button>
                    <el-button @click="toView()" type="primary" size="small">授权历史</el-button>
                </div>
                <div class="list" style="height: calc(100% - 92px);overflow-y: auto">
                    <el-table :data="list" style="width:100%">
                        <el-table-column prop="user_name" label="印章持有人">
                        </el-table-column>
                        <el-table-column prop="member_id" label="工号">
                        </el-table-column>
                        <el-table-column prop="mobile" label="手机号">
                        </el-table-column>
                        <el-table-column prop="name" label="部门">
                            <template slot-scope="scope">
                                <div v-if="scope.row.depts && scope.row.depts.length>0">{{scope.row.depts[0].name}}</div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="name" label="职位">
                            <template slot-scope="scope">
                                <div v-if="scope.row.roles && scope.row.roles.length>0">{{scope.row.roles[0].name}}</div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="address" label="操作">
                            <template slot-scope="scope">
                                <el-button type="text" size="small" @click="del(scope.row,scope.$index)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div style="padding:10px 0;">
                    <el-pagination background
                        layout="prev, pager, next"
                        :current-page.sync="opt.page"
                        :page-size="opt.pagesize"
                        :total="total"></el-pagination>
                </div>
            </div>
        </div>

        <el-dialog width="800px" :title="popTitle" :visible.sync="popVisible" :destroy-on-close="true">
            <StampItem v-if="popTitle=='新增持有人'" :form="ruleForm" @hide="hide"></StampItem>
            <StampHistory v-if="popTitle=='授权历史'" :form="ruleForm" @hide="hide"></StampHistory>
        </el-dialog>

    </div>
</template>
<script>
import { listSealMember, listSealLog, delSealMember } from "@/service/contract"
import StampItem from './StampItem.vue';
import StampHistory from './StampHistory.vue';
export default {
    components: {
        StampItem,StampHistory
    },
    data() {
        return {
            popTitle:"",
            popVisible:false,
            tabList:[
                {name:"设计作品版权出售合同章"},
                {name:"商品打样合同章"},
                {name:"需求定制合同章"},
            ],
            tabIndex:0,
            opt:{
                seal_type:1, // 印章类型，0保留，1设计作品版权出售合同章，2商品打样合同章，3代加工合同章
                page:1,
                pagesize:15,
            },
            total:15,
            list:[], // 印章持有人列表
            ruleForm:"", // 添加持有人
        }
    },
    created() {
        this.refreshItems();
    },
    mounted() {},
    methods:{
        // 选项卡
        tab(index) {
            this.tabIndex = index;
            this.opt.seal_type = index+1;
            this.refreshItems();
        },
        refreshItems() {
            console.log('refreshItems');
            listSealMember(this.opt).then(rst => {
                this.list = rst.list;
                this.total = rst.total;
                console.log(this.list);
            }).catch(err => {
                console.log(err);
                this.$message.error(err.message);
            });
        },
        toAdd() {
            this.popTitle = "新增持有人";
            this.popVisible = true;
            this.ruleForm = {
                seal_type: this.opt.seal_type,
                user_ids: [],
            }
        },
        toView() {
            this.popTitle = "授权历史";
            this.popVisible = true;
            this.ruleForm = {
                seal_type: this.opt.seal_type,
                user_ids: [],
            }
        },
        del(item) {
            let obj = {
                seal_type: item.seal_type,
                user_ids: [item.user_id]
            }
            this.$confirm("确定要删除此印章持有人？", "温馨提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                closeOnClickModal: false,
                type: "warning",
            }).then(() => {
                delSealMember(obj).then(rst => {
                    console.log(rst);
                    this.refreshItems();
                    this.$message.success("删除成功!");
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            });
        },
        hide() {
            this.popVisible=false;
            this.refreshItems();
        }
    }
}
</script>
<style scoped lang="less">
.tab_menu {
    margin: 15px 20px;
    .menu_item{
        display: inline-block;
        vertical-align: top;
        height: 36px;
        text-align: center;
        line-height: 36px;
        margin-right:40px;
        cursor: pointer;
        font-size: 14px;
        color: #4D4D4D;
    }
    .menu_item.cur{
        color: #1279FF;
        border-bottom: 2px solid #1279FF;
    }
}
.box {
    min-width: 1300px;
    height: calc(100% - 86px);
    padding: 0 20px;
}
.box-left {
    width: 360px;
    height: 100%;
    text-align: center;
    background: #fff;
    padding: 40px 0 60px;
    margin-right: 20px;
    font-size: 16px;
    color: #4D4D4D;
    line-height: 22px;
    .bl-title {
        font-weight: 600;
        margin-bottom: 16px;
    }
    .bl-name {
        color: #808080;
    }
    .bl-img {
        width: 82px;
        margin: 60px auto 12px;
        img {
            width: 100%;
        }
    }
}
.box-right {
    height: 100%;
    padding: 20px 20px 0;
    background: #fff;
}
</style>